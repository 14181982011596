<template>
  <div id="app">
    <div id="searchData">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline" style="text-align: center">


          <el-form-item>
            <el-select
                v-model="searchForm.prizeId"
                clearable
                placeholder="请选择奖品名"
                style="width:100%"
            >
              <el-option
                  v-for="item in prizzers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.productName" placeholder="——请按照收奖人名称搜索——"
                    class="center-text"></el-input>
        </el-form-item>

        <el-form-item>
          <el-select
              v-model="searchForm.status"
              clearable
              placeholder="请选择订单状态"
              style="width:100%"
          >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="search"  icon="el-icon-search">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="refresh" icon="el-icon-refresh">刷新</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- table -->
    <el-table :data="tableData" style="width: 100%" height="450">
      <!-- 序号 -->
      <el-table-column type="index" :index="indexMethod"></el-table-column>
      <el-table-column prop="userDy.nickName" label="收奖人" width="120">
      </el-table-column>

      <el-table-column prop="prizeArr.name" label="奖品" width="100">
      </el-table-column>

      <el-table-column prop="createTime" label="创建时间" width="160">
      </el-table-column>
      <el-table-column prop="status" label="是否核销" width="160">
        <template slot-scope="scope">
          <span>{{ scope.row.status === 0 ? '未核销' : '已核销' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <div>
            <!-- 如果 status 为 0，显示核销按钮 -->
            <el-button
                v-if="scope.row.status === 0"
                type="primary"
                size="mini"
                @click="handleWriteOff(scope.row)"
            >
              核销
            </el-button>

            <!-- 如果 status 为 1，显示已核销 -->
            <span v-else>已核销</span>
          </div>

        </template>
      </el-table-column>
    </el-table>
    <!--添加或者更新的form表单-->
    <div id="dialog">
      <!--添加和编辑的对话框-->
      <el-dialog :visible.sync="dialogFormVisible"
                 :before-close="handleClose">
        <el-form ref="addEditform" :rules="rules" :model="addEditform">
          <el-form-item label="奖品等级" :label-width="formLabelWidth" prop="type">
            <div class="left">
              <el-input
                  v-model="addEditform.type"
                  autocomplete="off"
                  placeholder="奖品等级"
              ></el-input>
            </div>
          </el-form-item>

          <el-form-item label="奖品名称" :label-width="formLabelWidth" prop="name">
            <div class="left">
              <el-input
                  v-model="addEditform.name"
                  autocomplete="off"
                  placeholder="奖品名称"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="奖品数量" :label-width="formLabelWidth" prop="num">
            <div class="left">
              <el-input
                  v-model="addEditform.num"
                  autocomplete="off"
                  placeholder="奖品数量"
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="enterEdit">确定编辑</el-button>
                  </span>
      </el-dialog>
    </div>
    <!--分页-->
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchForm.currentPage"
        :page-sizes="[1,2,5,10]"
        :page-size="searchForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>

export default {
  name: "Product",
  data() {
    return {
      prizzers:[],
      options: [
        {label: "未核销", value: 0},
        {label: "已核销", value: 1},

      ],
      tableData: [],
      optionsProduct: [],
      //搜索框内容
      searchForm: {
        currentPage: 1,
        pageSize: 5
      },
      total:0,
      dialogFormVisible: false,
      formLabelWidth: "120px",
      addEditform: {},
      //获取修改奖品类型
      optionsProductType: [],
      rules: {
        type: [{required: true, message: '奖品登记不能为空', trigger: 'change'}],
        /*picture: [{required: true, message: '图片不能为空', trigger: 'change'}],*/
        proTypeId: [{required: true, message: '奖品类型不能为空', trigger: 'change'}],
        name: [{required: true, message: '奖品名称不能为空', trigger: 'change'}],

        num: [{required: true, message: '数量不能为空', trigger: 'change'}],
      },


    }
  },
  created() {
    this.loadProduct();
    this.loadPrizzer();
  },
  methods: {
    search(){
      this.searchForm.currentPage = 1;
      this.searchForm.pageSize = 5;
      this.loadProduct();
    },
    loadPrizzer(){
      this.$http.get("/award/getAwards",this.searchForm).then(resp => {
        this.prizzers = resp.data.data
      })
    },
    handleWriteOff(row) {
      this.$http.put("/award/updateAwardStuts/" + row.id).then(resp => {
        console.log(resp)
        if (resp.data.code == 200) {
          this.$message({
            type: 'success',
            message: '更新成功!'
          });
          this.loadProduct();
        }
      })

    },
    //编辑按钮
    updataProduct(row) {
      this.dialogFormVisible = true;
      this.addEditform.id = row.id
      this.addEditform = row;
    },
    //确认编辑方法
    enterEdit() {
      this.$refs.addEditform.validate((valid) => {
        console.log(this.addEditform);
        if (valid) {
          this.$http.put("/award/updateAward", this.addEditform).then(resp => {
            console.log(resp)
            if (resp.data.code == 200) {
              this.$message({
                type: 'success',
                message: '更新成功!'
              });
              this.loadProduct();
            }
          })
          this.addEditform = {}
          this.dialogFormVisible = false;
        }
      })
    },

    //刷新
    refresh() {
      this.loadProduct();
    },

    //对话框的叉
    handleClose() {
      this.dialogFormVisible = false;
    },
    //添加按钮
    addProduct() {
      this.dialogFormVisible = true;
    },
    //是否上线
    isline(row) {
      return row.isline == 0 ? "是" : "否";
    },
    //显示索引
    indexMethod(index) {
      return index + 1;
    },
    loadProduct() {
      this.$http.post("/award/getAwardLog",this.searchForm).then(resp => {
        console.log(resp)
        this.tableData = resp.data.data.records
        this.total = resp.data.data.total;

      })
    },
    //每页数量发生变化
    handleSizeChange(val) {
      this.currentPage = 1;
      this.searchForm.pageSize = val;
      this.loadProduct();
    }
    ,
    //页码发生变化
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.loadProduct();
    }
  }
}
</script>

<style scoped>
/*上传图片的样式*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  align-items: center;
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 100px;
  padding-top: 78px;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.left {
  /* 设置弹出框中输入框的宽度和位置 */
  width: 87%;
  text-align: left;
}
</style>
