<template>
    <div>
        <div id="searchData">
            <el-form :inline="true" :model="ProductExamineVo" class="demo-form-inline" style="text-align: center">
                <el-form-item>
                    <el-input v-model="ProductExamineVo.name" placeholder="——请输入商品名称——"
                              class="center-text"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="search" icon="el-icon-search">搜索</el-button>
                </el-form-item>
                <el-form-item>n
                    <el-button type="primary" @click="refresh" icon="el-icon-refresh">刷新</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- table -->
        <el-table
                :data="tableData"
                style="width: 100%"

        >
            <el-table-column
                    prop="store.storeName"
                    label="门店名称"
                    width="120"
                    fixed
            >
            </el-table-column>
            <el-table-column
                    prop="productType.name" label="商品类型"
                    width="120"
                    fixed
            >
            </el-table-column>
            <el-table-column
                    prop="name"
                    label="商品名称"
                    width="120"
                    fixed
            >
            </el-table-column>
            <el-table-column
                    prop="picture"
                    label="图片路径"
                    width="120"
            >
                <template slot-scope="scope">
                    <el-image
                            style="width: 60px; height: 40px"
                            :src="scope.row.picture"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column
                    prop="loadPrice"
                    label="现价(元)"
                    width="120"
            >
            </el-table-column>
            <el-table-column
                    prop="loadPrice"
                    label="原价(元)"
                    width="120"
            >
            </el-table-column>
            <el-table-column
                    prop="freight"
                    label="运费(元)"
                    width="120"
            >
            </el-table-column>
            <el-table-column
                    prop="isline"
                    label="是否上线"
                    width="100"
                    :formatter="isline"
            >
            </el-table-column>
            <el-table-column
                    prop="productDetails"
                    label="商品详情"
                    width="150"
                    :show-overflow-tooltip="true"

            >
                <template slot-scope="scope">
                    <el-button
                            type="text"
                    @click="ondialogDetails(scope.row)"
                    >点击查看
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column
                    prop="prompt"
                    label="温馨提示"
                    width="150"
            >
            </el-table-column>
            <el-table-column
                    prop="isExamine"
                    label="审核状态"
                    width="150"
                    :formatter="isExamine"
            >
            </el-table-column>

            <el-table-column
                    prop="userName"
                    label="审核人"
                    width="120"

            >
            </el-table-column>
            <el-table-column
                    prop="examineOpinion"
                    label="审核意见"
                    width="150"
            >
            </el-table-column>
            <el-table-column
                    prop="examineTime"
                    label="审核时间"
                    width="180"
            >
            </el-table-column>
            <el-table-column
                    label="操作"
                    width="120"
                    fixed="right"
            >
                <template slot-scope="scope">
                    <el-button
                            v-if="scope.row.isExamine == 0"
                            size="mini"
                            type="primary"
                            @click="handleEdit(scope.$index, scope.row)"
                    >审核
                    </el-button> &nbsp;&nbsp;
                    <el-button
                            v-if="scope.row.isExamine == 1||scope.row.isExamine == 2"
                            size="mini"
                            type="info"
                            disabled
                    >已审核
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 显示商品详情的对话框 -->
        <el-dialog
                title="商品详情"
                :visible.sync="dialogDetailsVisible"
        >
            <div
                    v-html="productDetails"
                    class="productDetailsBox"
            >
            </div>
        </el-dialog>
        <!--分页功能-->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="current"
                :page-sizes="[1,2,5,10]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
        <el-dialog
                :title="title"
                :visible.sync="dialogFormVisible"
        >
            <el-form
                    :model="form"
                    ref="form"
            >
                <el-form-item
                        label="是否通过审核"
                        :label-width="formLabelWidth"
                >
                    <el-select
                            v-model="form.isExamine"
                            placeholder="请选择"
                    >
                        <el-option
                                v-for="item in options"
                                :label="item.label"
                                :value="item.value"
                                :key="item.label"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item
                        label="审核意见"
                        :label-width="formLabelWidth"
                >
                    <el-input
                            v-model="form.examineOpinion"
                            autocomplete="off"
                    ></el-input>
                </el-form-item>

            </el-form>
            <div
                    slot="footer"
                    class="dialog-footer"
            >
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button
                        type="primary"
                        @click="addOrUpdate"
                >确 定
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "ProductExamine",
        data(){
            return{
                dialogFormVisible: false,
                formLabelWidth: "120px",
                //审核弹窗信息
                form: {},
                options: [
                    {
                        value: 1,
                        label: "审核通过",
                    },
                    {
                        value: 2,
                        label: "审核不通过",
                    },
                ],
                IsExamineoptions: [
                    {
                        label: "全部",
                    },
                    {
                        value: 0,
                        label: "未审核",
                    },
                    {
                        value: 1,
                        label: "已通过",
                    },
                    {
                        value: 2,
                        label: "未通过",
                    },
                ],
                //刷新方法

                ProductExamineVo:{
                    name:"",
                    isExamine: '',
                },
                tableData:[],
                dialogDetailsVisible: false, //商品详情是否显示
                current: 1, //当前页数
                pageSize: 5,//每页的数量
                total: 0, //总options条数
                title: "审核",
            }
        },
        created() {
            this.selectData();
        },
        methods:{
            addOrUpdate(){
                //定义变量_this=this
                var _this = this;
                //axios向后台发请求
                this.$http
                    .post(
                        "/examine/api/updateExamine", this.form
                    ) //请求参数  json格式
                    .then(function (response) {
                        //response获取后台数据时，一定加data  response.data相当于从响应的body中获取
                        if (response.data.code == 2000) {
                            //提示成功
                            _this.$message({
                                message: "恭喜你，这是一条成功消息",
                                type: "success",
                            });
                            //关闭弹框
                            _this.dialogFormVisible = false;
                            //重新加载数据
                            _this.selectData();
                            _this.form.isExamine = '';
                            _this.form.examineOpinion = '';
                        }
                    })
                    .catch(function (error) {
                        //异常处理
                        // console.log(error);
                    });
            },
            //审核跳转
            handleEdit(index, row) {
                //让对话框显示
                this.dialogFormVisible = true;
                //让title值也发生变化
                this.title = "审核";
                //给表单赋值
                this.form.productExamineId = row.productExamineId;
                this.form.productId = row.productId;
            },
            //审核状态
            isExamine(row) {
                if (row.isExamine == 0) {
                    return "未审核";
                } else if (row.isExamine == 1) {
                    return "审核通过";
                } else {
                    return "审核未通过";
                }
            },
            //是否上线
            isline(row) {
                return row.isline == 0 ? "是" : "否";
            },
            refresh(){
                this.ProductExamineVo = {};
                this.ProductExamineVo.name = "";
                this.ProductExamineVo.isExamine = "";
                this.current = 1;
                this.pageSize = 5;
                this.selectData();
            },
            search(){
                this.selectData();
            },
            //分页查询所有信息
            selectData() {
                this.$http.post("/examine/api/select/"+this.current+"/"+this.pageSize, this.ProductExamineVo).then(result => {
                    console.log(result)
                    this.tableData = result.data.data.list;
                    this.total = result.data.data.total;
                })
            },
            //显示商品详情
            ondialogDetails(row) {
                this.dialogDetailsVisible = true;
                if (row.productDetails) {
                    this.productDetails = row.productDetails;
                } else {
                    this.productDetails =
                        "<p style='text-align: center;'>没有更多内容了</p>";
                }
            },
            //每页数量发生变化
            handleSizeChange(val) {
                this.pageSize = val;
                this.selectData();
            },
            //页码发生变化
            handleCurrentChange(val) {
                this.current= val;
                this.selectData();
            },
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }

    .avatar {
        width: 100px;
        height: 100px;
        display: block;
    }

    .left {
        /* 设置弹出框中输入框的宽度和位置 */
        width: 87%;
        text-align: left;
    }
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 150px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }

    /* 设置商品详情的样式 */
    .productDetailsBox {
        margin: 20px;
    }
</style>
