<template>
    <div id="app">
        <!-- table -->
        <el-table :data="tableData" style="width: 100%" height="450">
            <!-- 序号 -->
            <el-table-column type="index" :index="indexMethod"></el-table-column>
            <el-table-column prop="type" label="奖品等级" width="120">
            </el-table-column>

            <el-table-column prop="name" label="奖品内容" width="100">
            </el-table-column>

            <el-table-column prop="createTime" label="创建时间" width="160">
            </el-table-column>
            <el-table-column prop="num" label="奖品数量" width="160">
            </el-table-column>
            <el-table-column label="操作" width="200" >
                <template slot-scope="scope">
                    <el-button size="small" type="primary" v-if="scope.row.id!==5"
                               @click="updataProduct(scope.row)"
                               icon="el-icon-edit-outline">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--添加或者更新的form表单-->
        <div id="dialog">
            <!--添加和编辑的对话框-->
            <el-dialog :visible.sync="dialogFormVisible"
                       :before-close="handleClose">
                <el-form ref="addEditform" :rules="rules" :model="addEditform">
                  <el-form-item label="奖品等级" :label-width="formLabelWidth" prop="type">
                    <div class="left">
                      <el-input
                          v-model="addEditform.type"
                          autocomplete="off"
                          placeholder="奖品等级"
                      ></el-input>
                    </div>
                  </el-form-item>

                  <el-form-item label="奖品名称" :label-width="formLabelWidth" prop="name">
                        <div class="left">
                            <el-input
                                    v-model="addEditform.name"
                                    autocomplete="off"
                                    placeholder="奖品名称"
                            ></el-input>
                        </div>
                    </el-form-item>
                  <el-form-item label="奖品数量" :label-width="formLabelWidth" prop="num">
                    <div class="left">
                      <el-input
                          v-model="addEditform.num"
                          autocomplete="off"
                          placeholder="奖品数量"
                      ></el-input>
                    </div>
                  </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="enterEdit">确定编辑</el-button>
                  </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Product",
        data() {
            return {
                tableData: [],
                optionsProduct: [],
                searchForm:{},
                dialogFormVisible: false,
                formLabelWidth: "120px",
                addEditform: {

                },
                //获取修改奖品类型
                optionsProductType: [],
                rules: {
                    type: [{required: true, message: '奖品登记不能为空', trigger: 'change'}],
                    /*picture: [{required: true, message: '图片不能为空', trigger: 'change'}],*/
                    proTypeId: [{required: true, message: '奖品类型不能为空', trigger: 'change'}],
                    name: [{required: true, message: '奖品名称不能为空', trigger: 'change'}],

                    num: [{required: true, message: '数量不能为空', trigger: 'change'}],
                },


            }
        },
        created() {
            this.loadProduct();

        },
        methods:{

            //编辑按钮
            updataProduct(row) {
                this.dialogFormVisible = true;
                this.addEditform.id = row.id
                this.addEditform = row;
            },
            //确认编辑方法
            enterEdit() {
                this.$refs.addEditform.validate((valid) => {
                    console.log( this.addEditform);
                    if (valid) {
                        this.$http.put("/award/updateAward", this.addEditform).then(resp => {
                            console.log(resp)
                            if (resp.data.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功!'
                                });
                                this.loadProduct();
                            }
                        })
                        this.addEditform = {}
                        this.dialogFormVisible = false;
                    }
                })
            },

            //刷新
            refresh(){
                this.loadProduct();
            },

            //对话框的叉
            handleClose() {
                this.dialogFormVisible = false;
            },
            //添加按钮
            addProduct(){
                this.dialogFormVisible = true;
            },
            //是否上线
            isline(row) {
                return row.isline == 0 ? "是" : "否";
            },
            //显示索引
            indexMethod(index) {
                return index + 1;
            },
            loadProduct(){
                this.$http.get("/award/getAwards").then(resp => {
                    console.log(resp)
                    this.tableData = resp.data.data
                })
            },
            //每页数量发生变化
            handleSizeChange(val) {
                this.current=1;
                this.pageSize = val;
                this.loadProduct();
            }
            ,
            //页码发生变化
            handleCurrentChange(val) {
                this.current = val;
                this.loadProduct();
            }
        }
    }
</script>

<style scoped>
    /*上传图片的样式*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        align-items: center;
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 100px;
        padding-top: 78px;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .left {
        /* 设置弹出框中输入框的宽度和位置 */
        width: 87%;
        text-align: left;
    }
</style>
