<template>
  <div id="app">
    <!--标头 搜索框 开始-->
    <div id="searchData">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="searchForm.operName" placeholder="——请按照创建人搜索——"
                    class="center-text"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="searchStore" icon="el-icon-search">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addStore" icon="el-icon-circle-plus-outline">生成短链</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="flushedStore" icon="el-icon-refresh">刷新</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--标头 搜索框 结束-->

    <!--表格 数据 开始-->
    <div id="myTable">
      <el-table
          :data="tableData"
          height="500"
          border
          style="width: 100%">
        <el-table-column width="200" prop="wxName" label="小程序名称">
        </el-table-column>

        <el-table-column width="200" prop="appId" label="appid">
        </el-table-column>
        <el-table-column width="200" prop="appSerct" label="appserct">
        </el-table-column>
        <el-table-column width="150" prop="chain" label="短链链接">
        </el-table-column>
        <el-table-column label="状态" align="center" width="120">
          <template slot-scope="scope">
            <el-switch
                :value="scope.row.flag"
                :active-value="0"
                :inactive-value=1
                @change="handleStatusChange( scope.row)"
                active-color="#13ce66"
                inactive-color="#ff4949"
            >
            </el-switch>


          </template>

        </el-table-column>
        <el-table-column width="150" prop="wxShortChain" label="小程序跳转短链">
        </el-table-column>
        <el-table-column  width="150" prop="operName" label="创建人">
        </el-table-column>

        <el-table-column  width="150" prop="click" label="点击量">
        </el-table-column>
        <el-table-column

            width="180"
            prop="createTime"
            label="创建时间"
        >
        </el-table-column>
        <el-table-column

            width="180"
            prop="expiryTime"
            label="过期时间"
        >
        </el-table-column>
        <el-table-column
            label="操作"
            width="350"
            fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" @click="editStore(scope.row)" icon="el-icon-edit-outline">编辑
            </el-button>
            <el-button type="primary" @click="getIpShorts(scope.row.shorts)" icon="el-icon-edit-outline">点击记录
            </el-button>
            <el-button type="danger" @click="deleteStore(scope.row.sid)"
                       icon="el-icon-remove-outline">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="访问记录"
               :visible.sync="diaslogVisible"
               width="60%"
    >
      <div style="margin-bottom: 15px; text-align: right;">
        总访问次数：{{ totalVisits }}
      </div>
      <el-table :data="accessRecords">
        <el-table-column prop="ip" label="IP 地址" width="200"></el-table-column>
        <el-table-column prop="createTime" label="访问时间" width="250"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">

    <el-button type="primary" @click="enterip">确认</el-button>
  </span>
    </el-dialog>
    <!--表格 数据 结束-->
    <!--分页 信息开始-->
    <div id="pagination">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="searchForm.currentPage"
          :page-sizes="[1, 5, 10, 20]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <!--分页 信息结束-->
    <div id="dialog">
      <!--添加和编辑的对话框-->
      <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="40%"
          :before-close="handleClose">
        <el-form ref="addEditform" :rules="rules" :model="addEditform" label-width="150px">
          <el-form-item
              label="请输入小程序名称"
              prop="wxName"
          >
            <el-input v-model="addEditform.wxName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
              label="请输入appid"
              prop="appId"
          >
            <el-input v-model="addEditform.appId" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="域名">
            <el-select v-model="addEditform.url" placeholder="请选择域名">
              <el-option label="嘉沃" value="https://jw.aichitu.com/"></el-option>
              <el-option label="爱克森" value="https://supersms.cn/"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
              label="请输入appsert"
              prop="appSerct"
          >
            <el-input
                v-model="addEditform.appSerct"
                autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item label="有效期" prop="expiryTime">
            <el-input
                v-model.number="days"
                placeholder="0-365天"
                type="number"
                min="1"
                max="365"
                @change="convertDaysToDate"
                style="width: 200px"
            >
              <template slot="append">天</template>
            </el-input>
            <span class="tip-text" v-if="days">（到期时间：{{ addEditform.expiryTime }}）</span>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
    <el-button @click="cancelbutt">取 消</el-button>
    <el-button type="primary" @click="enterAdd" v-if="actor=='add'">确定添加</el-button>
    <el-button type="primary" @click="enterEdit" v-if="actor=='edit'">确定编辑</el-button>
  </span>
      </el-dialog>
    </div>


  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";

window._AMapSecurityConfig = {
  securityJsCode: '7971203447efcf714e19955cabf5d9be' //申请的安全密钥
}
export default {
  name: "store",
  data() {
    return {
      diaslogVisible:false,
      accessRecords: [],   // 存储访问记录数据（IP、时间）
      days: 7, // 默认7天
      //条件
      searchForm: {
        //分页
        currentPage: 1,
        pageSize: 5,
        operName: "",
      },
      tableData: undefined,
      total: 0,
      //对话框默认值
      dialogVisible: false,
      rules: {
        appId: [{required: true, message: 'appid不能为空', trigger: 'change'}],
        appSerct: [{required: true, message: 'appSerct不能为空', trigger: 'change'}],
        wxName: [{required: true, message: '小程序名称不能为空', trigger: 'change'}],
        url: [{required: true, message: '域名不能为空', trigger: 'change'}],

      },
      addEditform: {
        expiryTime:null
      },
      //文件上传路径
      uploadAction: '',
      actor: '',
      suggestions: [],
      autoComplete: null,
      //默认搜索地址
      searchMapForm: {
        name: ''
      },
      province: "", //省份
      city: "", //城市
      addressDetails: "", //详细地址
      longitude: "", //经度
      latitude: "", //纬度
      point: "", //中心点坐标
      country: "",
      //地图对话框
      dialogMapVisible: false,
    }
  },

  computed: {
    totalVisits() {
      return this.accessRecords.length;
    }
  },
  created() {
    this.loadStore();
    this.head = {
      token: sessionStorage.getItem("token")
    }
  },

  methods: {
    handleStatusChange(row) {
      console.log(row)
      // 保存原始值（使用深拷贝避免对象引用问题）
      const originalFlag = JSON.parse(JSON.stringify(row.flag))


      try {
        this.$confirm(`确定要${row.flag === 1 ? '启用' : '禁用'}该配置吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{

          // 用户确认后手动更新状态
          row.flag = row.flag === 0 ? 1 : 0
          this.$http.put(`/chain/updateFlagChain/`,{
            sid:row.sid,
            flag:row.flag
          }).then(resp => {
            if (resp.data.code === 200) {
              this.$message({
                type: 'success',
                message: resp.data.msg
              });
              this.searchForm.currentPage = 1;
              this.loadStore();
            }else if (resp.data.code === 400) {
              this.$message({
                type: 'error',
                message: resp.data.msg
              })
            }else {
              this.$message({
                type: 'info',
                message: '修改失败'
              })
            }
          })
        }).catch(()=>{
          console.log(666)
          // 取消操作时回滚状态
          row.flag = originalFlag; // 确保准确回滚
          this.$message({
            type: 'info',
            message:'已取消'
          })
        })
        // 确认后继续执行原逻辑
      } catch (cancel) {

      }
    },
    enterip(){
      this.diaslogVisible = false;
    },
    //查看点击记录
    getIpShorts(shorts) {
      this.diaslogVisible = true
      this.$http.get("/shortLink/getIpShort/" + shorts).then(resp => {
        if (resp.data.code === 200) {
          this.accessRecords = resp.data.data;
        }
      })
    },
    // 天数转日期
    convertDaysToDate() {
      console.log(this.days)
      if (this.days > 0) {
        const date = new Date()
        date.setDate(date.getDate() + this.days)
        this.addEditform.expiryTime = this.formatDate(date)
        console.log(this.addEditform.expiryTime)
      } else if (this.days == 0) {
        this.addEditform.expiryTime = null;
      }
    },

    // 日期格式化
    formatDate(date) {
      const pad = n => (n < 10 ? `0${n}` : n)
      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} 23:59:59`
    },

    //加载店铺
    loadStore() {
      this.$http.post("/chain/getAllChain",this.searchForm).then(resp => {
        console.log(resp)
        this.tableData = resp.data.data.records;
        this.total = resp.data.data.total;
      })
    },
    //取消按钮
    cancelbutt() {
      this.dialogVisible = false
      this.addEditform = {};
      this.loadStore();
    },
    //添加按钮的方法
    addStore() {
      this.addEditform.status = '0';
      this.actor = "add";
      this.dialogVisible = true;
    },
    //确认添加事件
    enterAdd() {
      this.convertDaysToDate()

      this.$refs.addEditform.validate((valid) => {
            if (valid) {
              this.$http.post("/chain/creatWXChain", this.addEditform).then(resp => {
                console.log(resp)
                if (resp.data.code === 200) {
                  this.$message({
                    type: 'success',
                    message: '添加成功!'
                  });
                  this.loadStore();
                }else if (resp.data.code === 500) {
                  this.$message({
                    type: 'error',
                    message: resp.data.msg
                  });
                }
              })
              this.actor = ''
              this.addEditform = {}
              this.dialogVisible = false;
            }
          }
      );
    },
    //修改按钮
    editStore(row) {
      this.actor = "edit"
      this.dialogVisible = true;
      this.addEditform = row;
    },
    //确认修改事件
    enterEdit() {
      console.log(this.addEditform);
      this.convertDaysToDate()
      this.$refs.addEditform.validate((valid) => {
        console.log(valid);
        if (valid) {
          this.actor = "";
          this.$http.put("/chain/updateChain", this.addEditform).then(resp => {
            if (resp.data.code === 200) {
              this.$message({
                type: 'success',
                message: '更新成功!'
              });
              this.loadStore();
            }else if (resp.data.code === 500) {
              this.$message({
                type: 'info',
                message:resp.data.msg
              });
            }
          })
          this.addEditform = {}
          this.dialogVisible = false;
        }
      });
    },
    //删除按钮事件
    deleteStore(sid) {
      console.log(sid)
      this.$confirm('是否删除该数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete("/chain/delChain/" + sid).then(resp => {
          if (resp.data.code === 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.searchForm.currentPage = 1;
            this.loadStore();
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    //
    openMap() {
    },
    //对话框关闭方法
    handleClose() {
      this.dialogVisible = false
      this.addEditform = {}
      this.loadStore();
    },
    //修改页大小
    handleSizeChange(num) {
      this.searchForm.pageSize = num;
      this.loadStore();
    },
    //修改当前页
    handleCurrentChange(num) {
      this.searchForm.currentPage = num;
      this.loadStore();
    },
    //搜索店名
    searchStore() {
      this.searchForm.currentPage = 1;
      this.loadStore();
    },
    //刷新按钮
    flushedStore() {
      this.searchForm.currentPage = 1;
      this.searchForm.pageSize = 5;
      this.loadStore();
    },





  }
}
</script>

<style scoped>
#searchData {
  float: left;
}

/*上传图片的样式*/
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;

  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  align-items: center;
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 70px;
  padding-top: 50px;
}

.avatar {
  width: 120px;
  height: 120px;

  display: block;
}

.center-text {
  /*text-align: center;*/
}
</style>
